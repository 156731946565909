import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContextProvider from "./Context/ContextProvider";
import About from "./Pages/About/About";
import AboutTwo from "./Pages/About/AboutTwo";
import Blog from "./Pages/Blog/Blog";
import BlogTwo from "./Pages/Blog/BlogTwo";
import Contact from "./Pages/Contact/Contact";
import ContactTwo from "./Pages/Contact/ContactTwo";
import Home from "./Pages/Home/Home";
import HomePage from "./Pages/Home/HomePage";
import HomeTwo from "./Pages/Home/HomeTwo";
import Portfilo from "./Pages/Portfilo/Portfilo";
import PortfiloTwo from "./Pages/Portfilo/PortfiloTwo";
import Resume from "./Pages/Resume/Resume";
import ResumeTwo from "./Pages/Resume/ResumeTwo";
import NotFound from "./Share/NotFound";

function App() {
    useEffect(() => {
        AOS.init({ duration: 1200 });
        AOS.refresh();
    }, []);
    return (
        <>
            <ContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/profile" element={<Home />}>
                            <Route index element={<HomePage />} />
                            <Route path="profile" element={<HomePage />} />
                            <Route path="resume" element={<Resume />} />
                            <Route path="about" element={<About />} />
                            <Route path="contact" element={<Contact />} />
                            <Route path="blogs" element={<Blog />} />
                            <Route path="works" element={<Portfilo />} />
                        </Route>
                        <Route path="/service" element={<HomeTwo />}>
                            <Route index element={<AboutTwo />} />
                            <Route path="service" element={<AboutTwo />} />
                            <Route path="devious100" element={<PortfiloTwo />} />
                            <Route path="drumnbass" element={<ResumeTwo />} />
                            <Route path="piffster" element={<BlogTwo />} />
                            <Route path="support" element={<ContactTwo />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </ContextProvider>
        </>
    );
}

export default App;
